import { ProgramSingleTabs } from '@hellodarwin/core/lib/components/programs/single';
import { RfpStatusFlowSteps } from '@hellodarwin/core/lib/components/rfp/status-flow';
import {
  AppPage,
  MappedAppPages,
} from '@hellodarwin/core/lib/features/entities/pages-entities';
import { lazy, useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useClientApi } from '../features/api/use-client-api';
import showErrorNotification from '../features/utils/show-error-notifications';
import ClientProjectsPage from './game-plan';
import ClientSinglePriorityPage from './game-plan/:priorityId';
import ClientSingleProjectPage from './game-plan/:priorityId/:projectId';

const ClientFileVaultPage = lazy(() => import('./client-file-vault'));
const ClientHomePage = lazy(() => import('./dashboard'));
const FAQClientPage = lazy(() => import('./faq'));
const ClientFundingExplorer = lazy(
  () => import('./funding-explorer/client-funding-explorer-page'),
);
const ClientFundingExplorerSingleGrantPage = lazy(
  () =>
    import(
      './funding-explorer/single-grant/client-funding-explorer-single-grant'
    ),
);
const ClientOngoingProgramsPage = lazy(
  () =>
    import('./program-pages/client-programs-page/client-roadmap-programs-page'),
);
const ClientSingleMilestonePage = lazy(
  () =>
    import(
      './program-pages/client-single-program-page/client-single-milestone-page'
    ),
);
const ClientSingleRoadmapProgram = lazy(
  () =>
    import(
      './program-pages/client-single-program-page/client-single-roadmap-program'
    ),
);
const ClientResourcesPage = lazy(() => import('./resources'));
const ClientResourcesSinglePage = lazy(
  () => import('./resources/[resourceSlug]'),
);
const ClientReviewsPage = lazy(() => import('./reviews'));
const ClientRfpPage = lazy(() => import('./rfp'));
const ClientSingleRfpPage = lazy(() => import('./rfp/[rfpId]'));
const ClientSingleProviderPage = lazy(
  () => import('./rfp/[rfpId]/providers/[matchId]'),
);
const ClientRfpStatusFlowPage = lazy(() => import('./rfp/[rfpId]/status'));
const ClientSettingsPage = lazy(() => import('./settings'));

const Redirect = ({ to }: { to: string }) => {
  const params = useParams();

  let finalTo = to;

  if (params) {
    Object.keys(params).forEach((paramName) => {
      // Use regex to replace `:paramName` in the `to` string
      finalTo = finalTo.replace(`:${paramName}`, params[paramName] || '');
    });
  }

  return <Navigate to={finalTo} />;
};

const transformChildren = (
  children: AppPage['children'],
  oldSegment: string,
  newSegment: string,
): AppPage['children'] => {
  if (!children) return undefined;

  return Object.entries(children).reduce((acc, [childPath, childPage]) => {
    const transformedPath = childPath.replace(oldSegment, newSegment);
    const transformPage: AppPage = {
      ...childPage,
      pathname: childPage.pathname.replace(oldSegment, newSegment),
      children: transformChildren(childPage.children, oldSegment, newSegment),
    };
    return {
      ...acc,
      [transformedPath]: transformPage,
    };
  }, {} as MappedAppPages);
};

const replaceSegment = (path: string, oldSegment: string, newSegment: string) =>
  path.replace(new RegExp(`(^|/)${oldSegment}(/|$)`), `$1${newSegment}$2`);

const transformChildrenIntoRedirect = (
  children: AppPage['children'],
  oldSegment: string,
  newSegment: string,
): AppPage['children'] => {
  if (!children) return undefined;

  return Object.entries(children).reduce((acc, [childPath, childPage]) => {
    const transformedPath = childPage.pathname.replace(oldSegment, newSegment);
    const transformPage: AppPage = {
      ...childPage,
      component: <Redirect to={transformedPath} />,
      children: transformChildrenIntoRedirect(
        childPage.children,
        oldSegment,
        newSegment,
      ),
    };
    return {
      ...acc,
      [childPath]: transformPage,
    };
  }, {} as MappedAppPages);
};

const BillingRedirect = () => {
  const api = useClientApi();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const session = await api.fetchBillingSession(
          window?.location?.origin || '',
        );
        window.location.href = session.url;
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
        navigate('/');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
const useAppPages = () => {
  return useMemo(() => {
    const oldProjectsPages: MappedAppPages = {
      '/projects': {
        pathname: '/projects',
        title: 'Projects',
        component: <ClientRfpPage />,
        children: {
          ':rfpId': {
            pathname: '/projects/:rfpId',
            title: 'Single Project',
            component: <ClientSingleRfpPage defaultTab="overview" />,
            children: {
              project: {
                pathname: '/projects/:rfpId/project',
                title: 'Redirect to Brief',
                component: <Redirect to="/rfp/:rfpId/brief" />,
              },
              brief: {
                pathname: '/projects/:rfpId/brief',
                title: 'Brief',
                component: <ClientSingleRfpPage defaultTab="brief" />,
              },
              matches: {
                pathname: '/projects/:rfpId/matches',
                title: 'Redirect to Candidates',
                component: <Redirect to="/rfp/:rfpId/candidates" />,
              },
              candidates: {
                pathname: '/projects/:rfpId/candidates',
                title: 'Candidates',
                component: <ClientSingleRfpPage defaultTab="candidates" />,
              },
              messages: {
                pathname: '/projects/:rfpId/messages',
                title: 'Messages',
                component: <ClientSingleRfpPage defaultTab="messages" />,
              },
              status: {
                pathname: '/projects/:rfpId/status',
                title: 'Status',
                component: <ClientRfpStatusFlowPage />,
                children: {
                  cancel: {
                    pathname: '/projects/:rfpId/status/cancel',
                    title: 'Cancel RFP',
                    component: (
                      <ClientRfpStatusFlowPage
                        initialStep={RfpStatusFlowSteps.CancelRfpFlow}
                      />
                    ),
                  },
                  'chosen-sp': {
                    pathname: '/projects/:rfpId/status/chosen-sp',
                    title: 'Chosen Service Provider',
                    component: (
                      <ClientRfpStatusFlowPage
                        initialStep={RfpStatusFlowSteps.PartnerChooseSelected}
                      />
                    ),
                  },
                  'contact-manager': {
                    pathname: '/projects/:rfpId/status/contact-manager',
                    title: 'Contact Manager',
                    component: (
                      <ClientRfpStatusFlowPage
                        initialStep={
                          RfpStatusFlowSteps.NoPartnerSelectedOptions
                        }
                      />
                    ),
                  },
                },
              },
              'providers/:matchId': {
                pathname: '/projects/:rfpId/providers/:matchId',
                title: 'Single Provider',
                component: <ClientSingleProviderPage />,
              },
            },
          },
        },
      },
    };

    const projectsRedirects = Object.entries(oldProjectsPages).reduce(
      (acc, [pathname, page]) => {
        const newPathname = replaceSegment(pathname, 'projects', 'rfp');
        const newPage: AppPage = {
          ...page,
          pathname: replaceSegment(page.pathname, 'projects', 'rfp'),
          children: transformChildren(page.children, 'projects', 'rfp'),
        };

        const oldPage: AppPage = {
          ...page,
          title: `Redirect to ${newPathname}`,
          component: <Redirect to={newPathname} />,
          children: transformChildrenIntoRedirect(
            page.children,
            'projects',
            'rfp',
          ),
        };

        return { ...acc, [pathname]: oldPage, [newPathname]: newPage };
      },
      {} as MappedAppPages,
    );

    const appPages: MappedAppPages = {
      '/dashboard': {
        pathname: '/dashboard',
        title: 'Dashboard',
        component: <ClientHomePage />,
      },
      ...projectsRedirects,
      '/billing': {
        pathname: '/billing',
        title: 'Billing',
        component: <BillingRedirect />,
      },
      '/resources': {
        pathname: '/resources',
        title: 'Resources',
        component: <ClientResourcesPage />,
        children: {
          ':resourceSlug': {
            pathname: '/resources/:resourceSlug',
            title: 'Single Resource',
            component: <ClientResourcesSinglePage />,
          },
        },
      },
      '/reviews': {
        pathname: '/reviews',
        title: 'Reviews',
        component: <ClientReviewsPage />,
      },
      '/profile': {
        pathname: '/profile',
        title: 'Profile',
        component: <Navigate to="/profile/contact" />,
        children: {
          contact: {
            pathname: '/profile/contact',
            title: 'Contact',
            component: <ClientSettingsPage defaultActiveKey="contact" />,
          },
          company: {
            pathname: '/profile/company',
            title: 'Company',
            component: <ClientSettingsPage defaultActiveKey="company" />,
          },
          users: {
            pathname: '/profile/users',
            title: 'Users',
            component: <ClientSettingsPage defaultActiveKey="users" />,
          },
        },
      },
      '/faq': {
        pathname: '/faq',
        title: 'FAQ',
        component: <FAQClientPage />,
      },
      '/programs': {
        pathname: '/programs',
        title: 'Programs',
        component: <Navigate to="/programs/roadmap" />,
        children: {
          roadmap: {
            pathname: '/programs/roadmap',
            title: 'Roadmap',
            component: <ClientOngoingProgramsPage />,
          },
          all: {
            pathname: '/programs/all',
            title: 'All Programs',
            component: <Navigate to="/programs/roadmap" />,
          },
          'digital-transformations': {
            pathname: '/programs/digital-transformations',
            title: 'Digital Transformations',
            component: <Navigate to="/programs/roadmap" />,
          },
          ':programId': {
            pathname: '/programs/:programId',
            title: 'Single Program',
            component: (
              <ClientSingleRoadmapProgram
                defaultSection={ProgramSingleTabs.ProgramInfo}
              />
            ),
            children: {
              files: {
                pathname: '/programs/:programId/files',
                title: 'Program Files',
                component: (
                  <ClientSingleRoadmapProgram
                    defaultSection={ProgramSingleTabs.ProgramFiles}
                  />
                ),
              },
            },
          },
          'milestones/:milestoneId': {
            pathname: '/programs/milestones/:milestoneId',
            title: 'Milestone',
            component: <ClientSingleMilestonePage />,
          },
        },
      },
      '/propulsion': {
        pathname: '/propulsion',
        title: 'Propulsion Redirect',
        component: <Navigate to="/programs/roadmap" />,
        children: {
          ':programId': {
            pathname: '/propulsion/:programId',
            title: 'Programs Redirect',
            component: <Redirect to="/programs/:programId" />,
          },
          'milestones/:milestoneId': {
            pathname: '/propulsion/milestones/:milestoneId',
            title: 'Milestones Redirect',
            component: <Redirect to="/programs/milestones/:milestoneId" />,
          },
        },
      },
      '/funding-explorer': {
        pathname: '/funding-explorer',
        title: 'Funding Explorer',
        component: <ClientFundingExplorer />,
        children: {
          ':id': {
            pathname: '/funding-explorer/:id',
            title: 'Single Grant',
            component: <ClientFundingExplorerSingleGrantPage />,
          },
        },
      },
      '/files-vault': {
        pathname: '/files-vault',
        title: 'File Vault',
        component: <ClientFileVaultPage />,
        children: {
          ':folderId': {
            pathname: '/files-vault/:folderId',
            title: 'Folder',
            component: <ClientFileVaultPage />,
          },
        },
      },
      '/game-plan': {
        pathname: '/game-plan',
        title: 'Game Plan',
        component: <ClientProjectsPage />,
        children: {
          ':priorityId': {
            pathname: '/game-plan/:priorityId',
            title: 'Single Priority',
            component: <ClientSinglePriorityPage />,
            children: {
              ':projectId': {
                pathname: '/game-plan/:priorityId/:projectId',
                title: 'Single Project',
                component: <ClientSingleProjectPage />,
                children: {},
              },
            },
          },
        },
      },
    };

    return appPages;
  }, []);
};

export default useAppPages;
