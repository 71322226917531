import { IconProps } from '@hellodarwin/icons/dist/features/entities/general';
import classNames from 'classnames';
import { CSSProperties, ReactNode, useMemo } from 'react';
import Modal, { ModalProps } from '..';
import parse from '../../../../features/helpers/parse';
import theme from '../../../../theme';
import Div from '../../div';
import Typography from '../../typography';
import { TypographyAlignement } from '../../typography/entities';

export type ModalLayoutWithIconSize = 24 | 32 | 48 | 64 | 80 | 96 | 128;
export interface ModalLayoutWithIconProps extends ModalProps {
  Icon: (props: IconProps) => ReactNode;
  iconSize?: ModalLayoutWithIconSize;
  iconColor?: string;
  title: string;
  description?: string;
  warning?: string;
  Actions: ReactNode;
  direction?: 'column' | 'row';
  withoutBorder?: boolean;
}

const ModalLayoutWithIcon = ({
  Icon,
  iconSize = 32,
  iconColor,
  title,
  description,
  warning,
  children,
  Actions,
  direction = 'column',
  noPadding,
  withoutBorder,
  ...modalProps
}: ModalLayoutWithIconProps) => {
  const align: TypographyAlignement = useMemo(
    () => (direction === 'column' ? 'center' : undefined),
    [direction],
  );
  const style: CSSProperties = useMemo(
    () => (direction === 'row' ? { flex: 1 } : undefined),
    [direction],
  );
  const classes = classNames('hd-modal-with-icon', {
    [`hd-modal-horizontal`]: direction === 'row',
  });

  const midIconSize = useMemo(() => iconSize / 2, [iconSize]);
  return (
    <Modal
      {...modalProps}
      noPadding={noPadding}
      styles={{
        ...modalProps?.styles,
        body: {
          overflow: 'auto',
          height: '100%',
          ...modalProps?.styles?.body,
        },
        content: {
          padding: noPadding ? 0 : '32px 115px',
          paddingTop: 54,
          overflow: 'visible',
          maxHeight: `calc(95vh - ${midIconSize}px)`,
          ...modalProps?.styles?.content,
        },
      }}
    >
      <Icon
        size={iconSize}
        color={iconColor}
        style={{
          background: theme.colors.white_1,
          padding: !withoutBorder ? iconSize / 8 : 0,
          aspectRatio: 1,
          borderRadius: 99,
          overflow: 'visible',
          border: !withoutBorder
            ? `2px solid ${theme.colors.purple_2}`
            : undefined,
          position: 'absolute',
          top: -midIconSize,
          left: '50%',
          transform: 'translateX(-50%)',
          color: iconColor,
        }}
      />
      <Div
        flex="column"
        align="center"
        className={classes}
        gap={32}
        fitContent
        style={{ margin: 'auto' }}
      >
        <Div flex={direction} align="center" gap={32} fitContent>
          <Div
            flex="column"
            gap={24}
            align={align}
            style={{ width: direction === 'row' ? 350 : undefined }}
          >
            <Div style={style} flex="column" align={align} gap={20}>
              <Typography.Title level={3} textAlign={align}>
                {title}
              </Typography.Title>
              {description && (
                <Typography textAlign={align}>{parse(description)}</Typography>
              )}
            </Div>
            {!!warning && (
              <Typography
                bold
                elementTheme="body2"
                letterSpacing={0.32}
                textAlign={align}
              >
                {warning}
              </Typography>
            )}
          </Div>
          {!!children && <Div style={style}>{children}</Div>}
        </Div>
        <Div
          flex="row"
          gap={24}
          align="center"
          fitMaxContent
          style={{ width: '100%' }}
          tablet={{ flex: 'column-reverse' }}
        >
          {Actions}
        </Div>
      </Div>
    </Modal>
  );
};

export default ModalLayoutWithIcon;
